/* eslint-disable */
$(function() {
    let HistoryItem = $('.js-project-section'),
        HistoryItemTop = [],
        yearList = []

    $.each(HistoryItem, function (index, value) {
        console.log("$(value).height() =" + $(value).height());
        HistoryItemTop.push($(value).offset().top + $(value).height()/2);
        yearList.push($(value).data("year"));
    });

    $( window ).scroll(function() {
        let scroll = $(window).scrollTop();

        $.each(HistoryItemTop, function (index, value) {
           if (scroll < value) {
              $(".js-date-line").removeClass("is-active");
              $(".js-date-line[data-year="+yearList[index]+"]").addClass("is-active");
              return false;
           }
        });
    });

    $(".js-date-line").on( "click", function() {
        let year = $(this).data("year"),
            anchorTop = $(".js-project-section[data-year="+year+"]").offset().top;
        
        $(".js-date-line").removeClass("is-active");
        $(this).addClass("is-active");
        $('html, body').stop().animate({
          scrollTop: anchorTop
        }, 1000);
    });
});





